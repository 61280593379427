import Filter from '@/views/components/filters/fields/Filter'
import { ref } from '@vue/composition-api'

export default class ServiceOrder extends Filter {
    filterComponent = import('@/views/components/filters/fields/orders/service/ServiceOrderFilterComponent.vue')

    static MODULE_NAME = 'service'

    static PRODUCT_RULES = 'rules'

    static SERVICE = 'service'

    static DAMAGED = 'damaged'

    fields = {
      [ServiceOrder.PRODUCT_RULES]: {
        [ServiceOrder.DAMAGED]: {
          label: 'Damaged',
          selectedValue: this.selectedOnlyLabel,
        },
        [ServiceOrder.SERVICE]: {
          label: 'Service',
          selectedValue: this.selectedOnlyLabel,
        },
      },
    }

    selectedFilters = ref()

    // eslint-disable-next-line class-methods-use-this
    getResponseFilter(response) {
      return response.data.meta.filters ?? {}
    }

    selectedOnlyLabel(label, value) {
      if (value) return super.selectedOnlyLabel(label)
      return null
    }
}
